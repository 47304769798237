import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import css from './Lightbox.module.scss';

const Lightbox = ({ images, selectedIndex, onClose, onPrev, onNext }) => {
  const handleClose = () => {
    onClose();
  };

  const [imageOpacity, setImageOpacity] = useState(0);

  useEffect(() => {
    // Add a delay to start the opacity transition
    const timeout = setTimeout(() => {
      setImageOpacity(1);
    }, 150); // Adjust the delay time

    // Cleanup: Clear the timeout on unmount
    return () => clearTimeout(timeout);
  }, [selectedIndex]);

  const handlePrev = () => {
    // Reset opacity to 0 before changing the selectedIndex
    setImageOpacity(0);
    onPrev();
  };

  const handleNext = () => {
    // Reset opacity to 0 before changing the selectedIndex
    setImageOpacity(0);
    onNext();
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key) {
        case 'ArrowLeft':
          if (selectedIndex > 0) {
            // Reset opacity to 0 before changing the selectedIndex
            setImageOpacity(0);
            onPrev();
          }
          break;
        case 'ArrowRight':
          if (selectedIndex < images.length - 1) {
            // Reset opacity to 0 before changing the selectedIndex
            setImageOpacity(0);
            onNext();
          }
          break;
        case 'Escape':
          handleClose();
          break;
        default:
          break;
      }
    };

    // Attach event listener when the component mounts
    window.addEventListener('keydown', handleKeyDown);

    // Cleanup: Remove event listener when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onPrev, onNext, onClose, selectedIndex, images.length]);

  return (
    <div className={css.lightbox_overlay}>
      <div className={css.lightbox_modal}>
        <div className={css.lightbox_close} onClick={handleClose}>
          <FontAwesomeIcon icon="fa-solid fa-xmark" />
        </div>
        <div className={css.lightbox_content}>
          {selectedIndex > 0 && ( // Display "Previous" button if not on the first image
            <div className={css.lightbox_nav_prev} onClick={handlePrev}>
              <FontAwesomeIcon icon="fa-solid fa-chevron-left" />
            </div>
          )}
          <img
            className={css.lightbox_image}
            src={images[selectedIndex]}
            alt={`Image ${selectedIndex}`}
            style={{ opacity: imageOpacity }}
          />
          {selectedIndex < images.length - 1 && ( // Display "Next" button if not on the final image
            <div className={css.lightbox_nav_next} onClick={handleNext}>
              <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Lightbox;
