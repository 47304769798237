import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ResponsiveProvider from 'utils/hooks/responsive';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronLeft,
  faChevronRight,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
library.add(faXmark, faChevronLeft, faChevronRight, faInstagram, faTiktok);

import Layout from './components/Layout';
import HomePage from './views/HomePage';
import AboutPage from './views/AboutPage';
import ContactPage from './views/ContactPage';
import PortfolioPage from './views/PortfolioPage';
import css from './App.scss';

function App() {
  return (
    <div className={css.main}>
      <ResponsiveProvider>
        <BrowserRouter>
          <Routes>
            {/* Layout is the page wrapper component that contains nav and footer */}
            <Route element={<Layout />}>
              <Route path="/" element={<HomePage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/portfolio" element={<PortfolioPage />} />
              {/* Add rest of the page here */}
            </Route>
          </Routes>
        </BrowserRouter>
      </ResponsiveProvider>
    </div>
  );
}

export default App;
