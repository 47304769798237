import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';

import { LogoWhite } from '../../assets/svgComponents';
import css from './Nav.module.scss';
import Drawer from '../../components/Drawer';
import { useResponsive } from '../../utils/hooks/responsive';

function Nav() {
  const {
    responsive: { breakpoint },
  } = useResponsive();
  return (
    <div className={css.page_wrapper}>
      <div className={css.column}>
        <div className={css.logo}>
          <NavLink to="/">
            <LogoWhite />
          </NavLink>
        </div>
        {breakpoint === 'mobile' ? (
          <div>
            <Drawer />
          </div>
        ) : null}
      </div>
      {breakpoint !== 'mobile' ? (
        <nav className={css.nav}>
          <ul className={css.nav_links}>
            <li>
              <NavLink to="/" exact="true">
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/about" exact="true">
                About
              </NavLink>
            </li>
            <li>
              <NavLink to="/portfolio" exact="true">
                Portfolio
              </NavLink>
            </li>
            <li>
              <NavLink to="/contact" exact="true">
                Contact
              </NavLink>
            </li>
          </ul>
        </nav>
      ) : null}
      <main className={css.main_content}>
        <Outlet />
      </main>
    </div>
  );
}

export default Nav;
