import image1 from '../../assets/images/black_sofa.jpg';
import image2 from '../../assets/images/DL5A3454.jpg';
import image3 from '../../assets/images/646A5222.jpg';
import image4 from '../../assets/images/646A5142.jpg';
import image5 from '../../assets/images/DL5A3838.jpg';

const Images = [
  {
    id: 1,
    src: image1,
    alt: 'Image 1',
  },
  {
    id: 2,
    src: image2,
    alt: 'Image 2',
  },
  {
    id: 3,
    src: image3,
    alt: 'Image 3',
  },
  {
    id: 4,
    src: image4,
    alt: 'Image 4',
  },
  {
    id: 5,
    src: image5,
    alt: 'Image 5',
  },
];

export default Images;
