import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import css from './Footer.module.scss';

function Footer() {
  return (
    <div className={css.footer_wrapper}>
      <div className={css.social_media}>
        <a
          href="http://www.instagram.com/studio_blacktx/"
          target="_blank"
          className={css.instagram}
          rel="noreferrer"
        >
          <FontAwesomeIcon icon="fa-brands fa-instagram" />
        </a>
      </div>
      <div className={css.footer}>
        <uli>Studio Black // Interior Design</uli>
      </div>
    </div>
  );
}

export default Footer;
