import React from 'react';

import profilePhoto from '../../assets/images/profile_photo.jpg';
import css from './AboutPage.module.scss';

function AboutPage() {
  return (
    <div className={css.page_wrapper}>
      <div className={css.row}>
        <div className={css.column}>
          <div className={css.image_wrapper}>
            <img className={css.image} src={profilePhoto} alt="" />
          </div>
          <div className={css.about}>
            <h1 className={css.h1}>ABOUT KIMBERLEY</h1>
            <p className={css.paragraph}>
              With years of experience and a keen eye for design, I am a
              passionate residential interior designer dedicated to creating
              beautiful spaces. From concept to completion, I work closely with
              my clients to bring their vision to life and exceed their
              expectations. My goal is to deliver spaces that are not only
              visually stunning but also functional and practical for everyday
              living.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutPage;
