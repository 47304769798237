import { TextField } from '@material-ui/core';
import React from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { YupValidation } from '../../schemas';
import BlackSofa2 from '../../assets/images/DL5A3454.jpg';
import css from './ContactPage.module.scss';
import serviceMethods from '../../service';

const ContactPage = () => {
  const initialValue = {
    name: '',
    email: '',
    phone: '',
    message: '',
  };

  const handleSubmit = (values, props) => {
    serviceMethods.submitContactForm(values);
    props.resetForm();
  };

  return (
    <div className={css.page_wrapper}>
      <div className={css.column}>
        <div className={css.image_wrapper}>
          <img className={css.image} src={BlackSofa2} alt="" />
        </div>
        <div className={css.contact}>
          <h1 className={css.h1}>GET IN TOUCH</h1>
          <p className={css.paragraph}>
            Please provide your contact information and a brief description of
            your needs. I will follow up with you shortly to schedule a
            consultation.
          </p>
        </div>
      </div>
      <Formik
        initialValues={initialValue}
        validationSchema={YupValidation}
        onSubmit={handleSubmit}
      >
        {(props) => {
          return (
            <Form className={css.fields_wrapper}>
              <Field
                as={TextField}
                label="Name"
                name="name"
                fullWidth
                helperText={<ErrorMessage name="name" />}
                error={props.errors.name && props.touched.name}
                required
                inputProps={{ style: { fontSize: '1.2rem' } }}
                InputLabelProps={{ style: { fontSize: '1.2rem' } }}
                FormHelperTextProps={{ style: { fontSize: '1rem' } }}
                sx={{
                  color: 'white',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'white',
                      borderRadius: '8px',
                    },
                    '&:hover fieldset': {
                      borderColor: 'white',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'white',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: 'white',
                  },
                  '& .MuiFormLabel-root': {
                    color: 'white',
                  },
                }}
              />
              <Field
                as={TextField}
                label="Email"
                name="email"
                fullWidth
                helperText={<ErrorMessage name="email" />}
                error={props.errors.email && props.touched.email}
                required
                inputProps={{ style: { fontSize: '1.2rem' } }}
                InputLabelProps={{ style: { fontSize: '1.2rem' } }}
                FormHelperTextProps={{ style: { fontSize: '1rem' } }}
                sx={{
                  color: 'white',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'white',
                      borderRadius: '8px',
                    },
                    '&:hover fieldset': {
                      borderColor: 'white',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'white',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: 'white',
                  },
                  '& .MuiFormLabel-root': {
                    color: 'white',
                  },
                }}
              />
              <Field
                as={TextField}
                label="Phone"
                name="phone"
                fullWidth
                helperText={<ErrorMessage name="phone" />}
                error={props.errors.phone && props.touched.phone}
                inputProps={{ style: { fontSize: '1.2rem' } }}
                InputLabelProps={{ style: { fontSize: '1.2rem' } }}
                FormHelperTextProps={{ style: { fontSize: '1rem' } }}
                sx={{
                  color: 'white',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'white',
                      borderRadius: '8px',
                    },
                    '&:hover fieldset': {
                      borderColor: 'white',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'white',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: 'white',
                  },
                  '& .MuiFormLabel-root': {
                    color: 'white',
                  },
                }}
              />
              <Field
                as={TextField}
                label="Message"
                name="message"
                fullWidth
                helperText={<ErrorMessage name="message" />}
                error={props.errors.message && props.touched.message}
                required
                multiline
                rows={5}
                maxRows={10}
                inputProps={{ style: { fontSize: '1.2rem' } }}
                InputLabelProps={{ style: { fontSize: '1.2rem' } }}
                FormHelperTextProps={{ style: { fontSize: '1rem' } }}
                sx={{
                  color: 'white',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'white',
                      borderRadius: '8px',
                    },
                    '&:hover fieldset': {
                      borderColor: 'white',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'white',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: 'white',
                  },
                  '& .MuiFormLabel-root': {
                    color: 'white',
                  },
                }}
              />
              <div className={css.button_wrapper}>
                <button
                  type="submit"
                  disabled={
                    props.errors.name ||
                    props.errors.email ||
                    props.errors.message
                  }
                  className={css.submit_button}
                >
                  LET&apos;S CHAT!
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ContactPage;
