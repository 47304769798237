import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import css from './Drawer.module.scss';

function Drawer() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const closeDrawer = () => {
    setIsOpen(false);
  };

  return (
    <nav>
      <div className={css.menu_toggle}>
        <input type="checkbox" checked={isOpen} onChange={toggleDrawer} />
        <span></span>
        <span></span>
        <span></span>
        <ul className={`${css.menu} ${isOpen ? css.open : ''}`}>
          <li>
            <NavLink to="/" onClick={closeDrawer}>
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/about" onClick={closeDrawer}>
              About
            </NavLink>
          </li>
          <li>
            <NavLink to="/portfolio" onClick={closeDrawer}>
              Portfolio
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact" onClick={closeDrawer}>
              Contact
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Drawer;
