import React from 'react';
// import { Outlet } from 'react-router-dom';
import Footer from '../Footer';
import Nav from '../Nav';
import css from './Layout.module.scss';

const Layout = () => {
  return (
    <div className={css.layout_wrapper}>
      <Nav />
      {/* <Outlet /> */}
      <Footer />
    </div>
  );
};

export default Layout;
