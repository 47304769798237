import React, { useState } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import Lightbox from '../../components/Lightbox';
import css from './PortfolioPage.module.scss';

import Design1 from '../../assets/images/646A5076.jpg';
import Design2 from '../../assets/images/646A5142.jpg';
import Design3 from '../../assets/images/646A5165.jpg';
import Design4 from '../../assets/images/646A5211.jpg';
import Design5 from '../../assets/images/646A5222.jpg';
import Design6 from '../../assets/images/646A5373.jpg';
import Design7 from '../../assets/images/646A5379.jpg';
import Design8 from '../../assets/images/DL5A3454.jpg';
import Design9 from '../../assets/images/DL5A3455.jpg';
import Design10 from '../../assets/images/DL5A3463.jpg';
import Design11 from '../../assets/images/DL5A3503.jpg';
import Design12 from '../../assets/images/DL5A3555.jpg';
import Design13 from '../../assets/images/DL5A3562.jpg';
import Design14 from '../../assets/images/DL5A3657.jpg';
import Design15 from '../../assets/images/DL5A3659.jpg';
import Design16 from '../../assets/images/DL5A3687.jpg';
import Design17 from '../../assets/images/DL5A3730.jpg';
import Design18 from '../../assets/images/DL5A3838.jpg';
import Design19 from '../../assets/images/DL5A3994.jpg';
import Design20 from '../../assets/images/DL5A3999.jpg';

const importedImages = [
  Design1,
  Design2,
  Design3,
  Design4,
  Design5,
  Design6,
  Design7,
  Design8,
  Design9,
  Design10,
  Design11,
  Design12,
  Design13,
  Design14,
  Design15,
  Design16,
  Design17,
  Design18,
  Design19,
  Design20,
];

const PortfolioPage = () => {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const openLightbox = (index) => {
    setSelectedImageIndex(index);
    setIsLightboxOpen(true);
  };

  const closeLightbox = () => {
    setIsLightboxOpen(false);
  };

  const navigatePrev = () => {
    if (selectedImageIndex > 0) {
      setSelectedImageIndex(selectedImageIndex - 1);
    }
  };

  const navigateNext = () => {
    if (selectedImageIndex < importedImages.length - 1) {
      setSelectedImageIndex(selectedImageIndex + 1);
    }
  };

  return (
    <div className={css.container}>
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
        <Masonry columnsCount={3} gutter="15px">
          {importedImages.map((image, i) => (
            <img
              onClick={() => openLightbox(i)}
              key={i}
              src={image}
              alt=""
              className={css.image}
            />
          ))}
        </Masonry>
      </ResponsiveMasonry>
      {isLightboxOpen && (
        <Lightbox
          images={importedImages}
          selectedIndex={selectedImageIndex}
          onClose={closeLightbox}
          onPrev={navigatePrev}
          onNext={navigateNext}
        />
      )}
    </div>
  );
};

export default PortfolioPage;
