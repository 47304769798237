import React from 'react';
import Slider from 'react-slick';

import css from './HomePage.module.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Images from './Images.js';

const HomePage = () => {
  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 1800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
  };
  return (
    <div className={css.homepage_slider_container}>
      <Slider {...settings}>
        {Images.map((item) => (
          <div key={item.id}>
            <img src={item.src} alt={item.alt} className={css.img} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default HomePage;
